<template>
  <div class="my-collection1">
    <div class="top">
      <div class="top-1">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item
            index="1"
            class="el-menu-demo-item"
          >
            我的藏品({{ totalNum }})
          </el-menu-item>
          <el-menu-item index="2">
            待拆盲盒({{ blindBoxTotalNum }})
          </el-menu-item>
        </el-menu>
      </div>
      <div
        v-if="activeIndex == 1"
        class="btns"
      >
        <!-- <van-icon
          @click="() => active = 0"
          :color="active == 0 ?'#FFFFFF' : '#D0D0D0'"
          name="apps-o"
        />
        
        <van-icon
          @click="() => active = 1"
          :color="active == 1 ?'#FFFFFF' : '#D0D0D0'"
          style="margin-left:0.5rem"
          name="bars"
        /> -->
        <div
          class="btns-1"
          @click="switchList(0)"
        >
          <img
            :src="require(`../assets/icon/media/list_${active == 0 ? 'active_' : ''}2.png`)"
            alt=""
          >
        </div>
        <div
          class="btns-2"
          @click="switchList(1)"
        >
          <img
            :src="require(`../assets/icon/media/list_${active == 1 ? 'active_' : ''}1.png`)"
            alt=""
          >
        </div>
      </div>
    </div>
    <!-- 列表 -->
    <div
      v-if="activeIndex == 1"
      class="list"
    >
      <!-- 上拉加载 -->
      <!-- <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="load"
        
      >
        
      </van-list> -->
      <div v-if="!isEmpty">
        <div
          v-if="active == 0"
          class="collection-list"
        >
          <div class="collection-list-left">
            <div
              class="collection-item"
              v-for="(item,index) in orderList.filter((i,j) => j%2 == 0)"
              :key="index"
              @click="goCollection(item, false)"
            >
              <div class="collection-item-container">
                <span class="collection-item-img">
                  <img
                    class="collection-img"
                    :src="item.image"
                  />
                </span>

                <div class="collection-content">
                  <p class="collection-item-name">{{ item.name }}</p>
                  <!-- <div class="row-start my-collection-item-id"> ID:{{item.uniqueCode}}</div> -->
                  <div class="row-between">
                    <p
                      class="collection-facName"
                      style=""
                    >{{ item.facName }}</p>
                    <img
                      style="width: 1rem;height: auto;margin-right: 0.625rem;"
                      :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                    />
                  </div>
                </div>
                <div class="item-no">
                  {{item.list.length}}
                </div>
                <img
                  v-if="item.exchange == 1 || item.exchange == 2 || item.exchange == 3"
                  class="conversion-img"
                  :src="require('../assets/icon/conversion.png')"
                />
              </div>
            </div>
          </div>
          <div class="collection-list-right">
            <div
              class="collection-item"
              v-for="(item,index) in orderList.filter((i,j) => j%2 == 1)"
              :key="index"
              @click="goCollection(item, false)"
            >
              <div class="collection-item-container">
                <span class="collection-item-img">
                  <img
                    class="collection-img"
                    :src="item.image"
                  />
                </span>
                <div class="collection-content">
                  <p class="collection-item-name">{{ item.name }}</p>
                  <!-- <div class="row-start my-collection-item-id"> ID:{{item.uniqueCode}}</div> -->
                  <div class="row-between">
                    <p
                      class="collection-facName"
                      style=""
                    >{{ item.facName }}</p>
                    <img
                      style="width: 1rem;height: auto;margin-right: 0.625rem;"
                      :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                    />
                  </div>
                </div>
                <div class="item-no">
                  {{item.list.length}}
                </div>
                <img
                  v-if="item.exchange == 1  || item.exchange == 2 || item.exchange ==3"
                  class="conversion-img"
                  :src="require('../assets/icon/conversion.png')"
                />
              </div>

            </div>
          </div>
        </div>
        <div
          v-else
          class="collection-list1"
        >
          <div
            class="list-li"
            v-if="imgList.length"
          >
            <div class="list-li-top">
              <div class="top-icon">
                <img
                  src="../assets/icon/media/img.png"
                  alt=""
                >
              </div>
              <div class="top-title">图画</div>
              <div class="top-num">{{imgList.length}}</div>
            </div>
            <div class="list-li-main">
              <div
                class="list-li-item list-li-item-tets"
                v-for="i in imgListShow"
                :key="i.id"
                @click="goCollection(i, true)"
              >
                <!-- 上链中状态添加 -->
                <div
                  v-if="i.chainStatus == '0'"
                  class="onchain-container row-center"
                >
                  <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                  <p>上链中</p>
                </div>
                <img
                  class="collection-img"
                  :src="i.image"
                  alt=""
                >
              </div>
              <div
                class="list-li-item last-item"
                v-if="imgShow"
                @click="more('img')"
              >
                <p>更多藏品</p>
              </div>
            </div>
          </div>
          <div
            class="list-li"
            v-if="audioList.length"
          >
            <div class="list-li-top">
              <div class="top-icon">
                <img
                  src="../assets/icon/media/audio.png"
                  alt=""
                >
              </div>
              <div class="top-title">音频</div>
              <div class="top-num">{{audioList.length}}</div>
            </div>
            <div class="list-li-main">
              <div
                class="list-li-item list-li-item-tets"
                v-for="i in audioListShow"
                :key="i.id"
                @click="goCollection(i, true)"
              >
                <!-- 上链中状态添加 -->
                <div
                  v-if="i.chainStatus == '0'"
                  class="onchain-container row-center"
                >
                  <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                  <p>上链中</p>
                </div>
                <img
                  class="collection-img"
                  :src="i.image"
                  alt=""
                >
              </div>
              <div
                class="list-li-item last-item"
                v-if="audioShow"
                @click="more('audio')"
              >
                <p>更多藏品</p>
              </div>
            </div>
          </div>
          <div
            class="list-li"
            v-if="videoList.length"
          >
            <div class="list-li-top">
              <div class="top-icon">
                <img
                  src="../assets/icon/media/video.png"
                  alt=""
                >
              </div>
              <div class="top-title">视频</div>
              <div class="top-num">{{videoList.length}}</div>
            </div>
            <div class="list-li-main">
              <div
                class="list-li-item list-li-item-tets"
                v-for="i in videoListShow"
                :key="i.id"
                @click="goCollection(i, true)"
              >
                <!-- 上链中状态添加 -->
                <div
                  v-if="i.chainStatus == '0'"
                  class="onchain-container row-center"
                >
                  <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                  <p>上链中</p>
                </div>
                <img
                  class="collection-img"
                  :src="i.image"
                  alt=""
                >
              </div>
              <div
                class="list-li-item last-item"
                v-if="videoShow"
                @click="more('video')"
              >
                <p>更多藏品</p>
              </div>
            </div>
          </div>
          <div
            class="list-li"
            v-if="modelList.length"
          >
            <div class="list-li-top">
              <div class="top-icon">
                <img
                  src="../assets/icon/media/3d.png"
                  alt=""
                >
              </div>
              <div class="top-title">3D</div>
              <div class="top-num">{{modelList.length}}</div>
            </div>
            <div class="list-li-main">
              <div
                class="list-li-item list-li-item-tets"
                v-for="i in modelListShow"
                :key="i.id"
                @click="goCollection(i, true)"
              >
                <!-- 上链中状态添加 -->
                <div
                  v-if="i.chainStatus == '0'"
                  class="onchain-container row-center"
                >
                  <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                  <p>上链中</p>
                </div>
                <img
                  class="collection-img"
                  :src="i.image"
                  alt=""
                >
              </div>
              <div
                class="list-li-item last-item"
                v-if="modelShow"
                @click="more('model')"
              >
                <p>更多藏品</p>
              </div>
            </div>
          </div>
          <!-- <div
            class="col-li"
            v-for="(item,index) in orderList"
            :key="'col' + index"
          >
            <div class="img">
              <img
                :src="item.image"
                alt=""
              >
            </div>
            <div class="info">
              <div class="info-m">
                <div class="title">{{ item.name }}</div>
                <div class="type">
                  <img src="../assets/icon/image.png" />
                </div>
                <div class="author">{{ item.facName }}</div>
              </div>

            </div>
          </div> -->
        </div>
      </div>
      <div
        v-else
        class="is-empty"
      >
        <img
          src="../assets/img/store/empty.png"
          alt=""
        >
      </div>
    </div>
    <!-- 盲盒列表 -->
    <div
      v-if="activeIndex == 2"
      class="list"
    >
      <div v-if="!blindBoxIsEmpty">
        <div class="collection-list">
          <div class="collection-list-left">
            <div
              class="collection-item"
              v-for="(item,index) in blindBoxList.filter((i,j) => j%2 == 0)"
              :key="index"
              @click="goCollection(item, false)"
            >
              <div class="collection-item-container">
                <span class="collection-item-img">
                  <img
                    class="collection-img"
                    :src="item.image"
                  />
                </span>

                <div class="collection-content">
                  <p class="collection-item-name">{{ item.name }}</p>
                  <!-- <div class="row-start my-collection-item-id"> ID:{{item.uniqueCode}}</div> -->
                  <div class="row-between">
                    <p
                      class="collection-facName"
                      style=""
                    >{{ item.facName }}</p>
                    <img
                      style="width: 1rem;height: auto;margin-right: 0.625rem;"
                      :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                    />
                  </div>
                </div>
                <div class="item-no">
                  {{item.list.length}}
                </div>
              </div>
            </div>
          </div>
          <div class="collection-list-right">
            <div
              class="collection-item"
              v-for="(item,index) in blindBoxList.filter((i,j) => j%2 == 1)"
              :key="index"
              @click="goCollection(item, false)"
            >
              <div class="collection-item-container">
                <span class="collection-item-img">
                  <img
                    class="collection-img"
                    :src="item.image"
                  />
                </span>
                <div class="collection-content">
                  <p class="collection-item-name">{{ item.name }}</p>
                  <!-- <div class="row-start my-collection-item-id"> ID:{{item.uniqueCode}}</div> -->
                  <div class="row-between">
                    <p
                      class="collection-facName"
                      style=""
                    >{{ item.facName }}</p>
                    <img
                      style="width: 1rem;height: auto;margin-right: 0.625rem;"
                      :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                    />
                  </div>
                </div>
                <div class="item-no">
                  {{item.list.length}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="is-empty"
        style="position: relative;"
      >
        <p style="position: absolute;top:40%;width:100%;text-align:center;font-size: 1.125rem;font-family: NotoSansHans-Regular, NotoSansHans;font-weight: 400;color: #999999;line-height: 24px;">暂无盲盒</p>
        <img
          src="../assets/img/store/empty-no-word.png"
          alt=""
        >
      </div>
    </div>
    <van-action-sheet
      v-model="show"
      :title="actionTitle"
    >
      <div
        v-if="active == 0"
        class="collection-list"
      >
        <div class="collection-list-left">
          <div
            class="collection-item"
            v-for="(item,index) in actionList.filter((i,j) => j%2 == 0)"
            :key="index"
            @click="goCollection(item, true)"
          >
            <div class="collection-item-container">
              <!-- 上链中状态添加 -->
              <div
                v-if="item.chainStatus == '0'"
                class="onchain-container row-center"
              >
                <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                <p>上链中</p>
              </div>
              <span class="collection-item-img">
                <img
                  class="collection-img"
                  :src="item.image"
                />
              </span>
              <div class="collection-content1">
                <p class="collection-item-name">{{ item.name }}</p>
                <div class="row-start my-collection-item-id"> ID:{{  item.showId  && item.showId != '0' ? item.showId : item.uniqueCode }}</div>
                <div class="row-between">
                  <p
                    class="collection-facName"
                    style=""
                  >{{ item.facName }}</p>
                  <img
                    style="width: 1rem;height: auto;margin-right: 0.625rem;"
                    :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="collection-list-right">
          <div
            class="collection-item"
            v-for="(item,index) in actionList.filter((i,j) => j%2 == 1)"
            :key="index"
            @click="goCollection(item, true)"
          >
            <div class="collection-item-container">
              <!-- 上链中状态添加 -->
              <div
                v-if="item.chainStatus == '0'"
                class="onchain-container row-center"
              >
                <img :src="require('../assets/icon/collection_loading' + collectionLoading + '.png')" />
                <p>上链中</p>
              </div>
              <span class="collection-item-img">
                <img
                  class="collection-img"
                  :src="item.image"
                />
              </span>
              <div class="collection-content1">
                <p class="collection-item-name">{{ item.name }}</p>
                <div class="row-start my-collection-item-id"> ID:{{item.showId  && item.showId != '0' ? item.showId: item.uniqueCode}}</div>
                <div class="row-between">
                  <p
                    class="collection-facName"
                    style=""
                  >{{ item.facName }}</p>
                  <img
                    style="width: 1rem;height: auto;margin-right: 0.625rem;"
                    :src="require(`../assets/icon/media/${mediaType[item.cateId]}.png`)"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </van-action-sheet>
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="$router.push('/login')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="$router.push('/login')"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import config from '../config'
import api from '../api/index-client'
import Cookies from 'js-cookie'
import Vue from 'vue';
import { Icon,List,ActionSheet } from 'vant';

Vue.use(ActionSheet);
Vue.use(List);
Vue.use(Icon);
import { Button,Dialog,Menu,MenuItem } from 'element-ui';
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Menu)
Vue.use(MenuItem)
export default {
  data () {
    return {
      activeIndex: '1',
      loginVisible: false,
      loading: false,
      finished: false,
      orderList: [],
      actionList: [],
      actionTitle: '',
      goodsParam: {
        page: 0,
        limit: 12,
      },
      active: 0,
      show: false,
      isEmpty: false,
      blindBoxIsEmpty: false,
      imgList: [],
      videoList: [],
      audioList: [],
      modelList: [],
      imgListShow: [],
      videoListShow: [],
      audioListShow: [],
      modelListShow: [],
      blindBoxList: [],
      mediaType: config.mediaType,
      num: 8,
      totalNum: 0,
      blindBoxTotalNum: 0,
      loadingStart: '3'
    }
  },
  mounted () {
    if (sessionStorage.getItem('active')) {
      this.active = Number(sessionStorage.getItem('active'))
    }
    this.$store.commit('SHOW_APPLOADING')
    if (!Cookies.get('Login')) {
      this.loginVisible = true
    } else {
      this.getCollectionList()
      this.getBlindBoxList()
    }
    this.orderList = []

  },
  computed: {
    imgShow () {
      return this.imgList.length != this.imgListShow.length
    },
    audioShow () {
      return this.audioList.length != this.audioListShow.length
    },
    videoShow () {
      return this.videoList.length != this.videoListShow.length
    },
    modelShow () {
      return this.modelList.length != this.modelListShow.length
    },
    collectionLoading () {
      setTimeout(() => {
        this.loadingStart = this.loadingStart == '3' ? '1' : this.loadingStart == '1' ? '2' : '3'
      },700)
      return this.loadingStart
    }
  },
  methods: {
    switchList (val) {

      if (this.active != val) {
        this.active = val
        this.getCollectionList()
      }

    },
    // 去藏品详情页面
    goCollection (e,bool) {
      if (!bool && e.list.length != 1) {
        this.show = true
        this.actionTitle = e.name
        this.actionList = e.list.map(i => {
          let param = JSON.parse(JSON.stringify(e))
          // delete param.list
          param.chainStatus = i.chainStatus
          param.id = i.id
          param.showId = i.showId
          param.uniqueCode = i.uniqueCode
          return param
        })
      } else {
        if (e.chainStatus == '0') {
          // this.$toast.fail('资产上链中！')
        } else if (e.list && e.list.length == 1) {
          sessionStorage.setItem('active',this.active)
          if (this.activeIndex == 2) {
            this.$router.push('/blindDetails?id=' + e.list[0].id)
          } else {
            this.$router.push('/collection?id=' + e.list[0].id)
          }


        } else {
          sessionStorage.setItem('active',this.active)
          if (this.activeIndex == 2) {
            this.$router.push('/blindDetails?id=' + e.id)
          } else {
            this.$router.push('/collection?id=' + e.id)
          }
        }
      }

    },
    //获取购买的藏品列表
    getCollectionList () {
      api
        // .post('assets/list2?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .post('assets/list?type=' + (this.active + 1) + '&skuType=0')
        .then(result => {

          if (result.data.success) {
            if (this.active == 0) {
              if (result.data.data.content.length) {
                // result.data.data.content.map((i,j) => {
                //   this.orderList.push(i)
                // })
                this.orderList = result.data.data.content
                this.totalNum = result.data.data.totalNum
              } else {
                this.isEmpty = true
              }

            } else {
              if (result.data.data.totalNum != 0) {
                this.totalNum = result.data.data.totalNum
                // 把类型一样的藏品重新组装
                let sortingList = {
                  img: [],
                  video: [],
                  audio: [],
                  model: []
                }
                result.data.data.content.map(i => {
                  if (this.mediaType[i.cateId] == 'img') {
                    sortingList['img'] = sortingList['img'].concat(i.list)
                  } else if (this.mediaType[i.cateId] == 'video') {
                    sortingList['video'] = sortingList['video'].concat(i.list)
                  } else if (this.mediaType[i.cateId] == 'audio') {
                    sortingList['audio'] = sortingList['audio'].concat(i.list)
                  } else if (this.mediaType[i.cateId] == '3d') {
                    sortingList['model'] = sortingList['model'].concat(i.list)
                  }
                })
                // 判断每种类型的藏品是否存在，num可调整，默认为8，大于8条则会隐藏剩余的藏品
                if (sortingList['img'].length) {
                  this.imgList = sortingList['img']
                  if (sortingList['img'].length > this.num) {
                    this.imgListShow = sortingList['img'].slice(0,this.num - 1)
                  } else {
                    this.imgListShow = sortingList['img']
                  }
                }
                if (sortingList['video'].length) {
                  this.videoList = sortingList['video']
                  if (sortingList['video'].length > this.num) {
                    this.videoListShow = sortingList['video'].slice(0,this.num - 1)
                  } else {
                    this.videoListShow = sortingList['video']
                  }

                }

                if (sortingList['audio'].length) {
                  this.audioList = sortingList['audio']
                  if (sortingList['audio'].length > this.num) {
                    this.audioListShow = sortingList['audio'].slice(0,this.num - 1)
                  } else {
                    this.audioListShow = sortingList['audio']
                  }

                }

                if (sortingList['model'].length) {
                  this.modelList = sortingList['model']
                  if (sortingList['model'].length > this.num) {
                    this.modelListShow = sortingList['model'].slice(0,this.num - 1)
                  } else {
                    this.modelListShow = sortingList['model']
                  }

                }
              } else {
                this.isEmpty = true
              }
            }

            // if (result.data.data.content.length < this.goodsParam.limit) {
            //   this.finished = true
            // } else {
            //   this.loading = false
            //   this.finished = false
            // }
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    // tab切换
    handleSelect (key,keyPath) {
      this.activeIndex = key
    },
    // 获取待拆盲盒列表
    getBlindBoxList () {
      api
        // .post('assets/list2?page=' + this.goodsParam.page + '&limit=' + this.goodsParam.limit)
        .post('assets/list?skuType=1')
        .then(result => {

          if (result.data.success) {
            if (result.data.data.content.length) {
              this.blindBoxList = result.data.data.content
              this.blindBoxTotalNum = result.data.data.totalNum
            } else {
              this.blindBoxIsEmpty = true
            }
          } else {
            this.finished = true
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    load () {
      this.loading = true
      this.goodsParam.page += 1
      // this.getCollectionList()
    },
    more (type) {
      // 默认取7条，当点击更多需要加1然后再加8判断总数超过列表总数
      switch (type) {
        case 'img':
          if (this.imgListShow.length + 1 + this.num < this.imgList.length) {
            this.imgListShow = this.imgList.slice(0,this.imgListShow.length + this.num)
          } else {
            this.imgListShow = this.imgList
          }
          break
        case 'audio':
          if (this.audioListShow.length + 1 + this.num < this.audioList.length) {
            this.audioListShow = this.audioList.slice(0,this.audioListShow.length + this.num)
          } else {
            this.audioListShow = this.audioList
          }
          break
        case 'video':
          if (this.videoListShow.length + 1 + this.num < this.videoList.length) {
            this.videoListShow = this.videoList.slice(0,this.videoListShow.length + this.num)
          } else {
            this.videoListShow = this.videoList
          }
          break
        case 'model':
          if (this.modelListShow.length + 1 + this.num < this.modelList.length) {
            this.modelListShow = this.modelList.slice(0,this.modelListShow.length + this.num)
          } else {
            this.modelListShow = this.modelList
          }
          break
        default:
          break
      }

    }
  }
}
</script>

<style lang="less" scoped>
.van-popup {
  background: #262626;
  .van-action-sheet__header {
    color: #ffffff;
  }
}
.my-collection1 {
  margin-top: 50px;
  padding-top: 1rem;
  padding-bottom: 50px;
  .my-collection {
    font-size: 1rem;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #292929;
    line-height: 1.125rem;
    margin: 1.1875rem 0 0.875rem 1.4375rem;
  }
  .collection-item-container,
  .list-li-item-tets {
    position: relative;
    .onchain-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      backdrop-filter: blur(5px);
      z-index: 200;
      img {
        width: 1.25rem !important;
        height: 1.25rem !important;
        margin-right: 0.4375rem !important;
        // animation: collectionLoadiing 0.5s 0.2s linear infinite !important;
      }
      p {
        font-size: 0.75rem;
        font-weight: 600;
        color: #ffffff;
        line-height: 1.0625rem;
      }
      @keyframes collectionLoadiing {
        100% {
          transform: rotate(120deg);
        }
      }
    }
  }
  .collection-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    padding-bottom: 3.125rem;
  }

  .collection-list-left,
  .collection-list-right {
    width: 44%;
    height: auto;
  }
  .top {
    display: flex;
    justify-content: space-between;
    width: 92vw;
    margin-left: 4vw;
    span {
      margin-left: 1rem;
      font-family: lantingheiweight;
      font-weight: 300;
      color: #ffffff;
      font-size: 1.5rem;
    }
  }
  .top-1 {
    display: flex;
    align-items: center;
    .store-no {
      height: 1.25rem;
      width: 2.5rem;
      background: url('../assets/img/store/store_no.png') no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1c1c1c;
      font-size: 0.75rem;
      margin-left: 0.625rem;
    }
  }
  .btns {
    margin-right: 0.5rem;
    display: flex;
    justify-content: left;
    align-items: center;
    .btns-1,
    .btns-2 {
      display: flex;
      justify-content: left;
      align-items: center;
      width: 1.25rem;
      height: 1.125rem;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .btns-2 {
      margin-left: 1.125rem;
      margin-top: 0.01rem;
    }
  }
  .list {
    margin-top: 1rem;
  }
  .collection-list1 {
    padding: 0 1rem;
  }
  .row-between {
    height: 1.0625rem;
    line-height: 1.0625rem;
    margin-top: 0.2rem;
  }
  .collection-item {
    width: 100%;
    height: auto;

    margin-bottom: 0.625rem;
    border-radius: 4px;
    border-color: transparent;
    overflow: hidden;
    position: relative;
    &-container {
      background: #d0d0d0;
    }
    .collection-item-img {
      width: 44vw;
      height: 44vw;
      display: block;
      position: relative;
      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }
    .collection-content {
      height: 2.5625rem;
      background: #3c3c3c;
      padding: 0.5rem 0;
    }
    .collection-content1 {
      // height: 2.5625rem;
      background: #3c3c3c;
      padding: 0.5rem 0;
    }
    .collection-img {
      width: 100%;
      height: auto;

      display: block;
    }
    .collection-facName {
      margin-left: 0.6875rem;
      color: #c6c3c2;
      font-size: 0.75rem;
    }
    .collection-item-name {
      font-size: 1rem;
      font-weight: 500;
      color: #ffffff;
      height: 1.25rem;
      line-height: 1.25rem;
      margin-left: 0.6875rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .my-collection-item-id {
      margin: 0.3125rem 0.6875rem 0.5625rem 0.6875rem;
      // padding-left: 0.4rem;
      height: auto;
      // padding: 0.625rem;
      font-size: 0.75rem;
      font-weight: normal;
      color: #c6c3c2;
      line-height: 1.0625rem;
      // background: #f2f2f2;
      border-radius: 0.125rem;
      word-break: break-all;
    }
    .item-no {
      width: 1.875rem;
      height: 1.5rem;
      background: #000000;
      border-radius: 0px 6.25rem 6.25rem 0px;
      opacity: 0.65;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 0.75rem;
      position: absolute;
      left: 0;
      top: 0.9375rem;
    }
    .conversion-img {
      position: absolute;
      right: 2px;
      top: 2px;
      width: 33px;
      height: auto;
    }
  }
  .col-li {
    width: 100%;
    height: 7rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background: #fff;
    position: relative;
    padding-top: 1rem;
    .img {
      width: 6rem;
      height: 6rem;
      border-radius: 0.3rem;
      top: 50%;
      left: 1rem;
      transform: translateY(-50%);
      border: 1px solid #dcdcdc;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
      }
    }
    .info {
      height: 6rem;
      margin: 0rem 0 0 8rem;
      &-m {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .author {
        }
        .title {
          color: #666666;
        }
      }
    }
  }
  .list-li {
    .list-li-top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .top-icon {
        width: 0.9375rem;
        height: auto;
        img {
          width: 0.9375rem;
          height: auto;
          display: block;
        }
      }
      .top-title {
        margin-left: 0.5625rem;
        color: #ffffff;
        font-size: 0.875rem;
      }
      .top-num {
        // width: 30px;
        padding: 0.05rem 0.5625rem;
        height: 1.4rem;
        line-height: 1.4rem;
        background: #000000;
        border-radius: 0.75rem;
        // opacity: 0.79;
        text-align: center;
        margin-left: 0.5625rem;
        color: #ffffff;
      }
    }
    .list-li-main {
      // .list-li-main-container {

      // }
      width: 22rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 0.625rem;
      .list-li-item {
        width: 5rem;
        height: 5rem;
        background: #d0d0d0;
        margin: 0 0.5rem 0.5rem 0;
        border-radius: 0.25rem;
        overflow: hidden;
      }
      .last-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #dbdbdb;
        background: #686462;
        font-size: 0.8125rem;
        p {
          width: 1.875rem;
        }
      }
      .list-li-item-tets {
        // background-size: 100% auto;
        // width: 100%;
        // height: 100%;
        display: block;
        position: relative;
        .collection-img {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
        }
      }
    }
  }
  .is-empty {
    img {
      width: 100%;
      height: auto;
    }
  }
  .el-menu-demo-item {
    margin-right: 20px;
  }
  .el-menu--horizontal > .el-menu-item {
    font-size: 16px;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    font-size: 18px;
  }
}
</style>